import { getImgPath } from '@/shared/js/helpers'

/** Unisex sizes */
const sizeCentimeters = {
  chest: [
    { value: '76-84', label: '76-84', name: 'XXSMALL', shortName: 'XXS' },
    { value: '85-94', label: '85-94', name: 'XSMALL', shortName: 'XS' },
    { value: '95-104', label: '95-104', name: 'SMALL', shortName: 'S' },
    { value: '105-112', label: '105-112', name: 'MEDIUM', shortName: 'M' },
    { value: '113-120', label: '113-120', name: 'LARGE', shortName: 'L' },
    { value: '121-130', label: '121-130', name: 'XLARGE', shortName: 'XL' },
    { value: '131-140', label: '131-140', name: 'XXLARGE', shortName: 'XXL' },
    { value: '141-150', label: '141-150', name: '3XLARGE', shortName: '3XL' },
    { value: '151-160', label: '151-160', name: '4XLARGE', shortName: '4XL' },
    { value: '161-168', label: '161-168', name: '5XLARGE', shortName: '5XL' },
    { value: '173-178', label: '173-178', name: '6XLARGE', shortName: '6XL' },
  ],
  sleeve: [
    { value: '66.5', label: '66.5', name: 'XXSMALL', shortName: 'XXS' },
    { value: '67', label: '67', name: 'XSMALL', shortName: 'XS' },
    { value: '68', label: '68', name: 'SMALL', shortName: 'S' },
    { value: '68.5', label: '68.5', name: 'MEDIUM', shortName: 'M' },
    { value: '69', label: '69', name: 'LARGE', shortName: 'L' },
    { value: '70', label: '70', name: 'XLARGE', shortName: 'XL' },
    { value: '70.5', label: '70.5', name: 'XXLARGE', shortName: 'XXL' },
    { value: '71', label: '71', name: '3XLARGE', shortName: '3XL' },
    { value: '76.5', label: '76.5', name: '4XLARGE', shortName: '4XL' },
    { value: '77.5', label: '77.5', name: '5XLARGE', shortName: '5XL' },
    { value: '78', label: '78', name: '6XLARGE', shortName: '6XL' },
  ],
}

const sizeInches = {
  chest: [
    { value: '30-33', label: '30-33', name: 'XXSMALL', shortName: 'XXS' },
    { value: '34-37', label: '34-37', name: 'XSMALL', shortName: 'XS' },
    { value: '38-41', label: '38-41', name: 'SMALL', shortName: 'S' },
    { value: '42-44', label: '42-44', name: 'MEDIUM', shortName: 'M' },
    { value: '44-47', label: '44-47', name: 'LARGE', shortName: 'L' },
    { value: '48-51', label: '48-51', name: 'XLARGE', shortName: 'XL' },
    { value: '52-55', label: '52-55', name: 'XXLARGE', shortName: 'XXL' },
    { value: '56-59', label: '56-59', name: '3XLARGE', shortName: '3XL' },
    { value: '60-63', label: '60-63', name: '4XLARGE', shortName: '4XL' },
    { value: '64-66', label: '64-66', name: '5XLARGE', shortName: '5XL' },
    { value: '68-70', label: '68-70', name: '6XLARGE', shortName: '6XL' },
  ],
  sleeve: [
    { value: '26.25', label: '26.25', name: 'XXSMALL', shortName: 'XXS' },
    { value: '26.5', label: '26.5', name: 'XSMALL', shortName: 'XS' },
    { value: '26.75', label: '26.75', name: 'SMALL', shortName: 'S' },
    { value: '27', label: '27', name: 'MEDIUM', shortName: 'M' },
    { value: '27.25', label: '27.25', name: 'LARGE', shortName: 'L' },
    { value: '27.5', label: '27.5', name: 'XLARGE', shortName: 'XL' },
    { value: '27.75', label: '27.75', name: 'XXLARGE', shortName: 'XXL' },
    { value: '28', label: '28', name: '3XLARGE', shortName: '3XL' },
    { value: '30.25', label: '30.25', name: '4XLARGE', shortName: '4XL' },
    { value: '30.5', label: '30.5', name: '5XLARGE', shortName: '5XL' },
    { value: '30.75', label: '30.75', name: '6XLARGE', shortName: '6XL' },
  ],
}

const sizeCentimetersCroptop = {
  chest: [
    { value: '66-71', label: '66-71', name: 'XXSMALL', shortName: 'XXS' },
    { value: '76-81', label: '76-81', name: 'XSMALL', shortName: 'XS' },
    { value: '86-91', label: '86-91', name: 'SMALL', shortName: 'S' },
    { value: '96-101', label: '96-101', name: 'MEDIUM', shortName: 'M' },
    { value: '101-107', label: '101-107', name: 'LARGE', shortName: 'L' },
    { value: '112-117', label: '112-117', name: 'XLARGE', shortName: 'XL' },
    { value: '122-127', label: '122-127', name: 'XXLARGE', shortName: 'XXL' },
    { value: '132-137', label: '132-137', name: '3XLARGE', shortName: '3XL' },
    { value: '142-147', label: '142-147', name: '4XLARGE', shortName: '4XL' },
    { value: '152-157', label: '152-157', name: '5XLARGE', shortName: '5XL' },
    { value: '162-167', label: '162-167', name: '6XLARGE', shortName: '6XL' },
  ],
  sleeve: [
    { value: '66', label: '66', name: 'XXSMALL', shortName: 'XXS' },
    { value: '66.7', label: '66.7', name: 'XSMALL', shortName: 'XS' },
    { value: '67.3', label: '67.3', name: 'SMALL', shortName: 'S' },
    { value: '68', label: '68', name: 'MEDIUM', shortName: 'M' },
    { value: '68.6', label: '68.6', name: 'LARGE', shortName: 'L' },
    { value: '69.2', label: '69.2', name: 'XLARGE', shortName: 'XL' },
    { value: '69.9', label: '69.9', name: 'XXLARGE', shortName: 'XXL' },
    { value: '70.5', label: '70.5', name: '3XLARGE', shortName: '3XL' },
    { value: '71.1', label: '71.1', name: '4XLARGE', shortName: '4XL' },
    { value: '76.8', label: '76.8', name: '5XLARGE', shortName: '5XL' },
    { value: '77.5', label: '77.5', name: '6XLARGE', shortName: '6XL' },
  ],
}

const sizeInchesCroptop = {
  chest: [
    { value: '26-28', label: '26-28', name: 'XXSMALL', shortName: 'XXS' },
    { value: '30-32', label: '30-32', name: 'XSMALL', shortName: 'XS' },
    { value: '34-36', label: '34-36', name: 'SMALL', shortName: 'S' },
    { value: '38-40', label: '38-40', name: 'MEDIUM', shortName: 'M' },
    { value: '40-42', label: '40-42', name: 'LARGE', shortName: 'L' },
    { value: '44-46', label: '44-46', name: 'XLARGE', shortName: 'XL' },
    { value: '48-50', label: '48-50', name: 'XXLARGE', shortName: 'XXL' },
    { value: '52-54', label: '52-54', name: '3XLARGE', shortName: '3XL' },
    { value: '56-58', label: '56-58', name: '4XLARGE', shortName: '4XL' },
    { value: '60-62', label: '60-62', name: '5XLARGE', shortName: '5XL' },
    { value: '64-66', label: '64-66', name: '6XLARGE', shortName: '6XL' },
  ],
  sleeve: [
    { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
    { value: '26.25', label: '26.25', name: 'XSMALL', shortName: 'XS' },
    { value: '26.5', label: '26.5', name: 'SMALL', shortName: 'S' },
    { value: '26.75', label: '26.75', name: 'MEDIUM', shortName: 'M' },
    { value: '27', label: '27', name: 'LARGE', shortName: 'L' },
    { value: '27.25', label: '27.25', name: 'XLARGE', shortName: 'XL' },
    { value: '27.5', label: '27.5', name: 'XXLARGE', shortName: 'XXL' },
    { value: '27.75', label: '27.75', name: '3XLARGE', shortName: '3XL' },
    { value: '28', label: '28', name: '4XLARGE', shortName: '4XL' },
    { value: '30.25', label: '30.25', name: '5XLARGE', shortName: '5XL' },
    { value: '30.5', label: '30.5', name: '6XLARGE', shortName: '6XL' },
  ],
}

const sizeInchesCoat = {
  chest: [
    { value: '45', label: '45', name: 'XXSMALL', shortName: 'XXS' },
    { value: '49', label: '49', name: 'XSMALL', shortName: 'XS' },
    { value: '53', label: '53', name: 'SMALL', shortName: 'S' },
    { value: '57', label: '57', name: 'MEDIUM', shortName: 'M' },
    { value: '61', label: '61', name: 'LARGE', shortName: 'L' },
    { value: '65', label: '65', name: 'XLARGE', shortName: 'XL' },
    { value: '69', label: '69', name: 'XXLARGE', shortName: 'XXL' },
    { value: '73', label: '73', name: '3XLARGE', shortName: '3XL' },
    { value: '77', label: '77', name: '4XLARGE', shortName: '4XL' },
    { value: '81', label: '81', name: '5XLARGE', shortName: '5XL' },
  ],
  sleeve: [
    { value: '26.5', label: '26.5', name: 'XXSMALL', shortName: 'XXS' },
    { value: '26.75', label: '26.75', name: 'XSMALL', shortName: 'XS' },
    { value: '27', label: '27', name: 'SMALL', shortName: 'S' },
    { value: '27.25', label: '27.25', name: 'MEDIUM', shortName: 'M' },
    { value: '27.5', label: '27.5', name: 'LARGE', shortName: 'L' },
    { value: '27.75', label: '27.75', name: 'XLARGE', shortName: 'XL' },
    { value: '28', label: '28', name: 'XXLARGE', shortName: 'XXL' },
    { value: '30.25', label: '30.25', name: '3XLARGE', shortName: '3XL' },
    { value: '30.5', label: '30.5', name: '4XLARGE', shortName: '4XL' },
    { value: '30.75', label: '30.75', name: '5XLARGE', shortName: '5XL' },
  ],
  back: [
    { value: '43', label: '43', name: 'XXSMALL', shortName: 'XXS' },
    { value: '43.5', label: '43.5', name: 'XSMALL', shortName: 'XS' },
    { value: '44', label: '44', name: 'SMALL', shortName: 'S' },
    { value: '44.5', label: '44.5', name: 'MEDIUM', shortName: 'M' },
    { value: '45', label: '45', name: 'LARGE', shortName: 'L' },
    { value: '45.5', label: '45.5', name: 'XLARGE', shortName: 'XL' },
    { value: '46', label: '46', name: 'XXLARGE', shortName: 'XXL' },
    { value: '46.5', label: '46.5', name: '3XLARGE', shortName: '3XL' },
    { value: '47', label: '47', name: '4XLARGE', shortName: '4XL' },
    { value: '47.5', label: '47.5', name: '5XLARGE', shortName: '5XL' },
  ],
}
const sizeCentimetersCoat = {
  chest: [
    { value: '114.3', label: '114.3', name: 'XXSMALL', shortName: 'XXS' },
    { value: '124.4', label: '124.4', name: 'XSMALL', shortName: 'XS' },
    { value: '134', label: '134', name: 'SMALL', shortName: 'S' },
    { value: '144', label: '144', name: 'MEDIUM', shortName: 'M' },
    { value: '154', label: '154', name: 'LARGE', shortName: 'L' },
    { value: '165', label: '165', name: 'XLARGE', shortName: 'XL' },
    { value: '175', label: '175', name: 'XXLARGE', shortName: 'XXL' },
    { value: '185', label: '185', name: '3XLARGE', shortName: '3XL' },
    { value: '195', label: '195', name: '4XLARGE', shortName: '4XL' },
    { value: '205', label: '205', name: '5XLARGE', shortName: '5XL' },
  ],
  sleeve: [
    { value: '67.3', label: '67.3', name: 'XXSMALL', shortName: 'XXS' },
    { value: '67.9', label: '67.9', name: 'XSMALL', shortName: 'XS' },
    { value: '68.6', label: '68.6', name: 'SMALL', shortName: 'S' },
    { value: '69.2', label: '69.2', name: 'MEDIUM', shortName: 'M' },
    { value: '69.9', label: '69.9', name: 'LARGE', shortName: 'L' },
    { value: '70.5', label: '70.5', name: 'XLARGE', shortName: 'XL' },
    { value: '71.1', label: '71.1', name: 'XXLARGE', shortName: 'XXL' },
    { value: '76.8', label: '76.8', name: '3XLARGE', shortName: '3XL' },
    { value: '77.5', label: '77.5', name: '4XLARGE', shortName: '4XL' },
    { value: '78.1', label: '78.1', name: '5XLARGE', shortName: '5XL' },
  ],
  back: [
    { value: '109.2', label: '109.2', name: 'XXSMALL', shortName: 'XXS' },
    { value: '110.5', label: '110.5', name: 'XSMALL', shortName: 'XS' },
    { value: '111.8', label: '111.8', name: 'SMALL', shortName: 'S' },
    { value: '113', label: '113', name: 'MEDIUM', shortName: 'M' },
    { value: '114.3', label: '114.3', name: 'LARGE', shortName: 'L' },
    { value: '115.6', label: '115.6', name: 'XLARGE', shortName: 'XL' },
    { value: '117', label: '117', name: 'XXLARGE', shortName: 'XXL' },
    { value: '118', label: '118', name: '3XLARGE', shortName: '3XL' },
    { value: '119', label: '119', name: '4XLARGE', shortName: '4XL' },
    { value: '120', label: '120', name: '5XLARGE', shortName: '5XL' },
  ],
}

export const sizesData = {
  Jacket: {
    centimeters: sizeCentimeters,
    inches: sizeInches,
  },
  Satinjack: {
    centimeters: sizeCentimeters,
    inches: sizeInches,
  },
  Hoodie: {
    centimeters: sizeCentimeters,
    inches: sizeInches,
  },
  Croptop: {
    centimeters: sizeCentimetersCroptop,
    inches: sizeInchesCroptop,
  },
  Coat: {
    centimeters: sizeCentimetersCoat,
    inches: sizeInchesCoat,
  },
}

/** End of Unisex sizes */

/** Table sizes */
export const sizesDataTable = {
  Jacket: {
    male: {
      centimeters: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '86-91', label: '86-91', name: 'XSMALL', shortName: 'XS' },
          { value: '96.5-101', label: '96.5-101', name: 'SMALL', shortName: 'S' },
          { value: '102-107', label: '102-107', name: 'MEDIUM', shortName: 'M' },
          { value: '112-117', label: '112-117', name: 'LARGE', shortName: 'L' },
          { value: '122-127', label: '122-127', name: 'XLARGE', shortName: 'XL' },
          { value: '132-137', label: '132-137', name: 'XXLARGE', shortName: '2XL' },
          { value: '142-147', label: '142-147', name: '3XLARGE', shortName: '3XL' },
          { value: '152-157', label: '152-157', name: '4XLARGE', shortName: '4XL' },
          { value: '163-168', label: '163-168', name: '5XLARGE', shortName: '5XL' },
          { value: '205', label: '205', name: '6XLARGE', shortName: '6XL' },
        ],
        hips: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '76', label: '76', name: 'XSMALL', shortName: 'XS' },
          { value: '81', label: '81', name: 'SMALL', shortName: 'S' },
          { value: '86', label: '86', name: 'MEDIUM', shortName: 'M' },
          { value: '91', label: '91', name: 'LARGE', shortName: 'L' },
          { value: '96.5', label: '96.5', name: 'XLARGE', shortName: 'XL' },
          { value: '102', label: '102', name: 'XXLARGE', shortName: '2XL' },
          { value: '107', label: '107', name: '3XLARGE', shortName: '3XL' },
          { value: '112', label: '112', name: '4XLARGE', shortName: '4XL' },
          { value: '117', label: '117', name: '5XLARGE', shortName: '5XL' },
          { value: '127', label: '127', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '67', label: '67', name: 'XSMALL', shortName: 'XS' },
          { value: '68', label: '68', name: 'SMALL', shortName: 'S' },
          { value: '68.5', label: '68.5', name: 'MEDIUM', shortName: 'M' },
          { value: '69', label: '69', name: 'LARGE', shortName: 'L' },
          { value: '70', label: '70', name: 'XLARGE', shortName: 'XL' },
          { value: '70.5', label: '70.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '71', label: '71', name: '3XLARGE', shortName: '3XL' },
          { value: '76', label: '76', name: '4XLARGE', shortName: '4XL' },
          { value: '77', label: '77', name: '5XLARGE', shortName: '5XL' },
          { value: '78', label: '78', name: '6XLARGE', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '34-36', label: '34-36', name: 'XSMALL', shortName: 'XS' },
          { value: '38-40', label: '38-40', name: 'SMALL', shortName: 'S' },
          { value: '40-42', label: '40-42', name: 'MEDIUM', shortName: 'M' },
          { value: '44-46', label: '44-46', name: 'LARGE', shortName: 'L' },
          { value: '48-50', label: '48-50', name: 'XLARGE', shortName: 'XL' },
          { value: '52-54', label: '52-54', name: 'XXLARGE', shortName: '2XL' },
          { value: '56-58', label: '56-58', name: '3XLARGE', shortName: '3XL' },
          { value: '60-62', label: '60-62', name: '4XLARGE', shortName: '4XL' },
          { value: '64-66', label: '64-66', name: '5XLARGE', shortName: '5XL' },
          { value: '81', label: '81', name: '6XLARGE', shortName: '6XL' },
        ],
        hips: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '30', label: '30', name: 'XSMALL', shortName: 'XS' },
          { value: '32', label: '32', name: 'SMALL', shortName: 'S' },
          { value: '34', label: '34', name: 'MEDIUM', shortName: 'M' },
          { value: '36', label: '36', name: 'LARGE', shortName: 'L' },
          { value: '38', label: '38', name: 'XLARGE', shortName: 'XL' },
          { value: '40', label: '40', name: 'XXLARGE', shortName: '2XL' },
          { value: '42', label: '42', name: '3XLARGE', shortName: '3XL' },
          { value: '44', label: '44', name: '4XLARGE', shortName: '4XL' },
          { value: '46', label: '46', name: '5XLARGE', shortName: '5XL' },
          { value: '50', label: '50', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '26.5', label: '26.5', name: 'XSMALL', shortName: 'XS' },
          { value: '26.75', label: '26.75', name: 'SMALL', shortName: 'S' },
          { value: '27', label: '27', name: 'MEDIUM', shortName: 'M' },
          { value: '27.25', label: '27.25', name: 'LARGE', shortName: 'L' },
          { value: '27.5', label: '27.5', name: 'XLARGE', shortName: 'XL' },
          { value: '27.75', label: '27.75', name: 'XXLARGE', shortName: '2XL' },
          { value: '28', label: '28', name: '3XLARGE', shortName: '3XL' },
          { value: '30.25', label: '30.25', name: '4XLARGE', shortName: '4XL' },
          { value: '30.5', label: '30.5', name: '5XLARGE', shortName: '5XL' },
          { value: '30.75', label: '30.75', name: '6XLARGE', shortName: '6XL' },
        ],
      },
    },
    female: {
      centimeters: {
        chest: [
          { value: '66-71', label: '66-71', name: 'XXSMALL', shortName: 'XXS' },
          { value: '76-81', label: '76-81', name: 'XSMALL', shortName: 'XS' },
          { value: '86-91', label: '86-91', name: 'SMALL', shortName: 'S' },
          { value: '96.5-101', label: '96.5-101', name: 'MEDIUM', shortName: 'M' },
          { value: '102-107', label: '102-107', name: 'LARGE', shortName: 'L' },
          { value: '112-117', label: '112-117', name: 'XLARGE', shortName: 'XL' },
          { value: '122-127', label: '122-127', name: 'XXLARGE', shortName: '2XL' },
          { value: '132-137', label: '132-137', name: '3XLARGE', shortName: '3XL' },
          { value: '142-147', label: '142-147', name: '4XLARGE', shortName: '4XL' },
          { value: '152-157', label: '152-157', name: '5XLARGE', shortName: '5XL' },
          { value: '163-168', label: '163-168', name: '6XLARGE', shortName: '6XL' },
        ],
        hips: [
          { value: '66', label: '66', name: 'XXSMALL', shortName: 'XXS' },
          { value: '71', label: '71', name: 'XSMALL', shortName: 'XS' },
          { value: '76', label: '76', name: 'SMALL', shortName: 'S' },
          { value: '81', label: '81', name: 'MEDIUM', shortName: 'M' },
          { value: '86', label: '86', name: 'LARGE', shortName: 'L' },
          { value: '91', label: '91', name: 'XLARGE', shortName: 'XL' },
          { value: '96.5', label: '96.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '102', label: '102', name: '3XLARGE', shortName: '3XL' },
          { value: '107', label: '107', name: '4XLARGE', shortName: '4XL' },
          { value: '112', label: '112', name: '5XLARGE', shortName: '5XL' },
          { value: '117', label: '117', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '65', label: '65', name: 'XXSMALL', shortName: 'XXS' },
          { value: '66', label: '66', name: 'XSMALL', shortName: 'XS' },
          { value: '67', label: '67', name: 'SMALL', shortName: 'S' },
          { value: '68', label: '68', name: 'MEDIUM', shortName: 'M' },
          { value: '68.5', label: '68.5', name: 'LARGE', shortName: 'L' },
          { value: '69', label: '69', name: 'XLARGE', shortName: 'XL' },
          { value: '70', label: '70', name: 'XXLARGE', shortName: '2XL' },
          { value: '70.5', label: '70.5', name: '3XLARGE', shortName: '3XL' },
          { value: '71', label: '71', name: '4XLARGE', shortName: '4XL' },
          { value: '76', label: '76', name: '5XLARGE', shortName: '5XL' },
          { value: '77', label: '77', name: '6XLARGE', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '26-28', label: '26-28', name: 'XXSMALL', shortName: 'XXS' },
          { value: '30-32', label: '30-32', name: 'XSMALL', shortName: 'XS' },
          { value: '34-36', label: '34-36', name: 'SMALL', shortName: 'S' },
          { value: '38-40', label: '38-40', name: 'MEDIUM', shortName: 'M' },
          { value: '40-42', label: '40-42', name: 'LARGE', shortName: 'L' },
          { value: '44-46', label: '44-46', name: 'XLARGE', shortName: 'XL' },
          { value: '48-50', label: '48-50', name: 'XXLARGE', shortName: '2XL' },
          { value: '52-54', label: '52-54', name: '3XLARGE', shortName: '3XL' },
          { value: '56-58', label: '56-58', name: '4XLARGE', shortName: '4XL' },
          { value: '60-62', label: '60-62', name: '5XLARGE', shortName: '5XL' },
          { value: '64-66', label: '64-66', name: '6XLARGE', shortName: '6XL' },
        ],
        hips: [
          { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
          { value: '28', label: '28', name: 'XSMALL', shortName: 'XS' },
          { value: '30', label: '30', name: 'SMALL', shortName: 'S' },
          { value: '32', label: '32', name: 'MEDIUM', shortName: 'M' },
          { value: '34', label: '34', name: 'LARGE', shortName: 'L' },
          { value: '36', label: '36', name: 'XLARGE', shortName: 'XL' },
          { value: '38', label: '38', name: 'XXLARGE', shortName: '2XL' },
          { value: '40', label: '40', name: '3XLARGE', shortName: '3XL' },
          { value: '42', label: '42', name: '4XLARGE', shortName: '4XL' },
          { value: '44', label: '44', name: '5XLARGE', shortName: '5XL' },
          { value: '46', label: '46', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
          { value: '26.25', label: '26.25', name: 'XSMALL', shortName: 'XS' },
          { value: '26.5', label: '26.5', name: 'SMALL', shortName: 'S' },
          { value: '26.75', label: '26.75', name: 'MEDIUM', shortName: 'M' },
          { value: '27', label: '27', name: 'LARGE', shortName: 'L' },
          { value: '27.25', label: '27.25', name: 'XLARGE', shortName: 'XL' },
          { value: '27.5', label: '27.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '27.75', label: '27.75', name: '3XLARGE', shortName: '3XL' },
          { value: '28', label: '28', name: '4XLARGE', shortName: '4XL' },
          { value: '30.25', label: '30.25', name: '5XLARGE', shortName: '5XL' },
          { value: '30.5', label: '30.5', name: '6XLARGE', shortName: '6XL' },
        ],
      },
    },
  },
  Coat: {
    male: {
      centimeters: {
        chest: [
          { value: '114', label: '114', name: 'XXSMALL', shortName: 'XXS' },
          { value: '124', label: '124', name: 'XSMALL', shortName: 'XS' },
          { value: '135', label: '135', name: 'SMALL', shortName: 'S' },
          { value: '145', label: '145', name: 'MEDIUM', shortName: 'M' },
          { value: '155', label: '155', name: 'LARGE', shortName: 'L' },
          { value: '165', label: '165', name: 'XLARGE', shortName: 'XL' },
          { value: '175', label: '175', name: 'XXLARGE', shortName: '2XL' },
          { value: '185', label: '185', name: '3XLARGE', shortName: '3XL' },
          { value: '196', label: '196', name: '4XLARGE', shortName: '4XL' },
          { value: '206', label: '206', name: '5XLARGE', shortName: '5XL' },
          { value: '216', label: '216', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '67', label: '67', name: 'XXSMALL', shortName: 'XXS' },
          { value: '68', label: '68', name: 'XSMALL', shortName: 'XS' },
          { value: '68.5', label: '68.5', name: 'SMALL', shortName: 'S' },
          { value: '69', label: '69', name: 'MEDIUM', shortName: 'M' },
          { value: '70', label: '70', name: 'LARGE', shortName: 'L' },
          { value: '70.5', label: '70.5', name: 'XLARGE', shortName: 'XL' },
          { value: '71', label: '71', name: 'XXLARGE', shortName: '2XL' },
          { value: '77', label: '77', name: '3XLARGE', shortName: '3XL' },
          { value: '77.5', label: '77.5', name: '4XLARGE', shortName: '4XL' },
          { value: '78', label: '78', name: '5XLARGE', shortName: '5XL' },
          { value: '79', label: '79', name: '6XLARGE', shortName: '6XL' },
        ],
        shoulderFull: [
          { value: '47', label: '47', name: 'XXSMALL', shortName: 'XXS' },
          { value: '48', label: '48', name: 'XSMALL', shortName: 'XS' },
          { value: '49.5', label: '49.5', name: 'SMALL', shortName: 'S' },
          { value: '51', label: '51', name: 'MEDIUM', shortName: 'M' },
          { value: '52', label: '52', name: 'LARGE', shortName: 'L' },
          { value: '53', label: '53', name: 'XLARGE', shortName: 'XL' },
          { value: '55', label: '55', name: 'XXLARGE', shortName: '2XL' },
          { value: '56', label: '56', name: '3XLARGE', shortName: '3XL' },
          { value: '57', label: '57', name: '4XLARGE', shortName: '4XL' },
          { value: '58', label: '58', name: '5XLARGE', shortName: '5XL' },
          { value: '59', label: '59', name: '6XLARGE', shortName: '6XL' },
        ],
        backLength: [
          { value: '109', label: '109', name: 'XXSMALL', shortName: 'XXS' },
          { value: '110.5', label: '110.5', name: 'XSMALL', shortName: 'XS' },
          { value: '112', label: '112', name: 'SMALL', shortName: 'S' },
          { value: '113', label: '113', name: 'MEDIUM', shortName: 'M' },
          { value: '114', label: '114', name: 'LARGE', shortName: 'L' },
          { value: '116', label: '116', name: 'XLARGE', shortName: 'XL' },
          { value: '117', label: '117', name: 'XXLARGE', shortName: '2XL' },
          { value: '118', label: '118', name: '3XLARGE', shortName: '3XL' },
          { value: '119', label: '119', name: '4XLARGE', shortName: '4XL' },
          { value: '121', label: '121', name: '5XLARGE', shortName: '5XL' },
          { value: '122', label: '122', name: '6XLARGE', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '45', label: '45', name: 'XXSMALL', shortName: 'XXS' },
          { value: '49', label: '49', name: 'XSMALL', shortName: 'XS' },
          { value: '53', label: '53', name: 'SMALL', shortName: 'S' },
          { value: '57', label: '57', name: 'MEDIUM', shortName: 'M' },
          { value: '61', label: '61', name: 'LARGE', shortName: 'L' },
          { value: '65', label: '65', name: 'XLARGE', shortName: 'XL' },
          { value: '69', label: '69', name: 'XXLARGE', shortName: '2XL' },
          { value: '73', label: '73', name: '3XLARGE', shortName: '3XL' },
          { value: '77', label: '77', name: '4XLARGE', shortName: '4XL' },
          { value: '81', label: '81', name: '5XLARGE', shortName: '5XL' },
          { value: '85', label: '85', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '26.5', label: '26.5', name: 'XXSMALL', shortName: 'XXS' },
          { value: '26.75', label: '26.75', name: 'XSMALL', shortName: 'XS' },
          { value: '27', label: '27', name: 'SMALL', shortName: 'S' },
          { value: '27.25', label: '27.25', name: 'MEDIUM', shortName: 'M' },
          { value: '27.5', label: '27.5', name: 'LARGE', shortName: 'L' },
          { value: '27.75', label: '27.75', name: 'XLARGE', shortName: 'XL' },
          { value: '28', label: '28', name: 'XXLARGE', shortName: '2XL' },
          { value: '30.25', label: '30.25', name: '3XLARGE', shortName: '3XL' },
          { value: '30.5', label: '30.5', name: '4XLARGE', shortName: '4XL' },
          { value: '30.75', label: '30.75', name: '5XLARGE', shortName: '5XL' },
          { value: '31', label: '31', name: '6XLARGE', shortName: '6XL' },
        ],
        shoulderFull: [
          { value: '18.5', label: '18.5', name: 'XXSMALL', shortName: 'XXS' },
          { value: '19', label: '19', name: 'XSMALL', shortName: 'XS' },
          { value: '19.5', label: '19.5', name: 'SMALL', shortName: 'S' },
          { value: '20', label: '20', name: 'MEDIUM', shortName: 'M' },
          { value: '20.5', label: '20.5', name: 'LARGE', shortName: 'L' },
          { value: '21', label: '21', name: 'XLARGE', shortName: 'XL' },
          { value: '21.5', label: '21.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '22', label: '22', name: '3XLARGE', shortName: '3XL' },
          { value: '22.5', label: '22.5', name: '4XLARGE', shortName: '4XL' },
          { value: '23', label: '23', name: '5XLARGE', shortName: '5XL' },
          { value: '23.5', label: '23.5', name: '6XLARGE', shortName: '6XL' },
        ],
        backLength: [
          { value: '43', label: '43', name: 'XXSMALL', shortName: 'XXS' },
          { value: '43.5', label: '43.5', name: 'XSMALL', shortName: 'XS' },
          { value: '44', label: '44', name: 'SMALL', shortName: 'S' },
          { value: '44.5', label: '44.5', name: 'MEDIUM', shortName: 'M' },
          { value: '45', label: '45', name: 'LARGE', shortName: 'L' },
          { value: '45.5', label: '45.5', name: 'XLARGE', shortName: 'XL' },
          { value: '46', label: '46', name: 'XXLARGE', shortName: '2XL' },
          { value: '46.5', label: '46.5', name: '3XLARGE', shortName: '3XL' },
          { value: '47', label: '47', name: '4XLARGE', shortName: '4XL' },
          { value: '47.5', label: '47.5', name: '5XLARGE', shortName: '5XL' },
          { value: '48', label: '48', name: '6XLARGE', shortName: '6XL' },
        ],
      },
    },
    female: {
      centimeters: {
        chest: [
          { value: '66-71', label: '66-71', name: 'XXSMALL', shortName: 'XXS' },
          { value: '114', label: '114', name: 'XSMALL', shortName: 'XS' },
          { value: '124', label: '124', name: 'SMALL', shortName: 'S' },
          { value: '135', label: '135', name: 'MEDIUM', shortName: 'M' },
          { value: '145', label: '145', name: 'LARGE', shortName: 'L' },
          { value: '155', label: '155', name: 'XLARGE', shortName: 'XL' },
          { value: '165', label: '165', name: 'XXLARGE', shortName: '2XL' },
          { value: '175', label: '175', name: '3XLARGE', shortName: '3XL' },
          { value: '185', label: '185', name: '4XLARGE', shortName: '4XL' },
          { value: '196', label: '196', name: '5XLARGE', shortName: '5XL' },
          { value: '206', label: '206', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '65', label: '65', name: 'XXSMALL', shortName: 'XXS' },
          { value: '67', label: '67', name: 'XSMALL', shortName: 'XS' },
          { value: '68', label: '68', name: 'SMALL', shortName: 'S' },
          { value: '68.5', label: '68.5', name: 'MEDIUM', shortName: 'M' },
          { value: '69', label: '69', name: 'LARGE', shortName: 'L' },
          { value: '70', label: '70', name: 'XLARGE', shortName: 'XL' },
          { value: '70.5', label: '70.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '71', label: '71', name: '3XLARGE', shortName: '3XL' },
          { value: '77', label: '77', name: '4XLARGE', shortName: '4XL' },
          { value: '77.5', label: '77.5', name: '5XLARGE', shortName: '5XL' },
          { value: '78', label: '78', name: '6XLARGE', shortName: '6XL' },
        ],
        shoulderFull: [
          { value: '64', label: '64', name: 'XXSMALL', shortName: 'XXS' },
          { value: '47', label: '47', name: 'XSMALL', shortName: 'XS' },
          { value: '48', label: '48', name: 'SMALL', shortName: 'S' },
          { value: '49.5', label: '49.5', name: 'MEDIUM', shortName: 'M' },
          { value: '51', label: '51', name: 'LARGE', shortName: 'L' },
          { value: '52', label: '52', name: 'XLARGE', shortName: 'XL' },
          { value: '53', label: '53', name: 'XXLARGE', shortName: '2XL' },
          { value: '55', label: '55', name: '3XLARGE', shortName: '3XL' },
          { value: '56', label: '56', name: '4XLARGE', shortName: '4XL' },
          { value: '57', label: '57', name: '5XLARGE', shortName: '5XL' },
          { value: '58', label: '58', name: '6XLARGE', shortName: '6XL' },
        ],
        backLength: [
          { value: '66', label: '66', name: 'XXSMALL', shortName: 'XXS' },
          { value: '109', label: '109', name: 'XSMALL', shortName: 'XS' },
          { value: '110.5', label: '110.5', name: 'SMALL', shortName: 'S' },
          { value: '112', label: '112', name: 'MEDIUM', shortName: 'M' },
          { value: '113', label: '113', name: 'LARGE', shortName: 'L' },
          { value: '114', label: '114', name: 'XLARGE', shortName: 'XL' },
          { value: '116', label: '116', name: 'XXLARGE', shortName: '2XL' },
          { value: '117', label: '117', name: '3XLARGE', shortName: '3XL' },
          { value: '118', label: '118', name: '4XLARGE', shortName: '4XL' },
          { value: '119', label: '119', name: '5XLARGE', shortName: '5XL' },
          { value: '121', label: '121', name: '6XLARGE', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '26-28', label: '26-28', name: 'XXSMALL', shortName: 'XXS' },
          { value: '45', label: '45', name: 'XSMALL', shortName: 'XS' },
          { value: '49', label: '49', name: 'SMALL', shortName: 'S' },
          { value: '53', label: '53', name: 'MEDIUM', shortName: 'M' },
          { value: '57', label: '57', name: 'LARGE', shortName: 'L' },
          { value: '61', label: '61', name: 'XLARGE', shortName: 'XL' },
          { value: '65', label: '65', name: 'XXLARGE', shortName: '2XL' },
          { value: '69', label: '69', name: '3XLARGE', shortName: '3XL' },
          { value: '73', label: '73', name: '4XLARGE', shortName: '4XL' },
          { value: '77', label: '77', name: '5XLARGE', shortName: '5XL' },
          { value: '81', label: '81', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
          { value: '26.5', label: '26.5', name: 'XSMALL', shortName: 'XS' },
          { value: '26.75', label: '26.75', name: 'SMALL', shortName: 'S' },
          { value: '27', label: '27', name: 'MEDIUM', shortName: 'M' },
          { value: '27.25', label: '27.25', name: 'LARGE', shortName: 'L' },
          { value: '27.5', label: '27.5', name: 'XLARGE', shortName: 'XL' },
          { value: '27.75', label: '27.75', name: 'XXLARGE', shortName: '2XL' },
          { value: '28', label: '28', name: '3XLARGE', shortName: '3XL' },
          { value: '30.25', label: '30.25', name: '4XLARGE', shortName: '4XL' },
          { value: '30.5', label: '30.5', name: '5XLARGE', shortName: '5XL' },
          { value: '30.75', label: '30.75', name: '6XLARGE', shortName: '6XL' },
        ],
        shoulderFull: [
          { value: '25.5', label: '25.5', name: 'XXSMALL', shortName: 'XXS' },
          { value: '18.5', label: '18.5', name: 'XSMALL', shortName: 'XS' },
          { value: '19', label: '19', name: 'SMALL', shortName: 'S' },
          { value: '19.5', label: '19.5', name: 'MEDIUM', shortName: 'M' },
          { value: '20', label: '20', name: 'LARGE', shortName: 'L' },
          { value: '20.5', label: '20.5', name: 'XLARGE', shortName: 'XL' },
          { value: '21', label: '21', name: 'XXLARGE', shortName: '2XL' },
          { value: '21.5', label: '21.5', name: '3XLARGE', shortName: '3XL' },
          { value: '22', label: '22', name: '4XLARGE', shortName: '4XL' },
          { value: '22.5', label: '22.5', name: '5XLARGE', shortName: '5XL' },
          { value: '23', label: '23', name: '6XLARGE', shortName: '6XL' },
        ],
        backLength: [
          { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
          { value: '43', label: '43', name: 'XSMALL', shortName: 'XS' },
          { value: '43.5', label: '43.5', name: 'SMALL', shortName: 'S' },
          { value: '44', label: '44', name: 'MEDIUM', shortName: 'M' },
          { value: '44.5', label: '44.5', name: 'LARGE', shortName: 'L' },
          { value: '45', label: '45', name: 'XLARGE', shortName: 'XL' },
          { value: '45.5', label: '45.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '46', label: '46', name: '3XLARGE', shortName: '3XL' },
          { value: '46.5', label: '46.5', name: '4XLARGE', shortName: '4XL' },
          { value: '47', label: '47', name: '5XLARGE', shortName: '5XL' },
          { value: '47.5', label: '47.5', name: '6XLARGE', shortName: '6XL' },
        ],
      },
    },
  },
  Hoodie: {
    male: {
      centimeters: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '39', label: '39', name: 'XSMALL', shortName: 'XS' },
          { value: '44', label: '44', name: 'SMALL', shortName: 'S' },
          { value: '49.5', label: '49.5', name: 'MEDIUM', shortName: 'M' },
          { value: '55', label: '55', name: 'LARGE', shortName: 'L' },
          { value: '61', label: '61', name: 'XLARGE', shortName: 'XL' },
          { value: '63.5', label: '63.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '61', label: '61', name: 'XSMALL', shortName: 'XS' },
          { value: '65', label: '65', name: 'SMALL', shortName: 'S' },
          { value: '70', label: '70', name: 'MEDIUM', shortName: 'M' },
          { value: '71', label: '71', name: 'LARGE', shortName: 'L' },
          { value: '72', label: '72', name: 'XLARGE', shortName: 'XL' },
          { value: '79', label: '79', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        back: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '60', label: '60', name: 'XSMALL', shortName: 'XS' },
          { value: '63.5', label: '63.5', name: 'SMALL', shortName: 'S' },
          { value: '67', label: '67', name: 'MEDIUM', shortName: 'M' },
          { value: '70', label: '70', name: 'LARGE', shortName: 'L' },
          { value: '71', label: '71', name: 'XLARGE', shortName: 'XL' },
          { value: '74', label: '74', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        bottom: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '34', label: '34', name: 'XSMALL', shortName: 'XS' },
          { value: '39', label: '39', name: 'SMALL', shortName: 'S' },
          { value: '43', label: '43', name: 'MEDIUM', shortName: 'M' },
          { value: '49.5', label: '49.5', name: 'LARGE', shortName: 'L' },
          { value: '55', label: '55', name: 'XLARGE', shortName: 'XL' },
          { value: '57', label: '57', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        shoulder: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '36', label: '36', name: 'XSMALL', shortName: 'XS' },
          { value: '39', label: '39', name: 'SMALL', shortName: 'S' },
          { value: '44', label: '44', name: 'MEDIUM', shortName: 'M' },
          { value: '48', label: '48', name: 'LARGE', shortName: 'L' },
          { value: '53', label: '53', name: 'XLARGE', shortName: 'XL' },
          { value: '56', label: '56', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '15.5', label: '15.5', name: 'XSMALL', shortName: 'XS' },
          { value: '17.5', label: '17.5', name: 'SMALL', shortName: 'S' },
          { value: '19.5', label: '19.5', name: 'MEDIUM', shortName: 'M' },
          { value: '21.5', label: '21.5', name: 'LARGE', shortName: 'L' },
          { value: '24', label: '24', name: 'XLARGE', shortName: 'XL' },
          { value: '25', label: '25', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '24', label: '24', name: 'XSMALL', shortName: 'XS' },
          { value: '25.5', label: '25.5', name: 'SMALL', shortName: 'S' },
          { value: '27.5', label: '27.5', name: 'MEDIUM', shortName: 'M' },
          { value: '28', label: '28', name: 'LARGE', shortName: 'L' },
          { value: '28.5', label: '28.5', name: 'XLARGE', shortName: 'XL' },
          { value: '31', label: '31', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        back: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '23.5', label: '23.5', name: 'XSMALL', shortName: 'XS' },
          { value: '25', label: '25', name: 'SMALL', shortName: 'S' },
          { value: '26.5', label: '26.5', name: 'MEDIUM', shortName: 'M' },
          { value: '27.5', label: '27.5', name: 'LARGE', shortName: 'L' },
          { value: '28', label: '28', name: 'XLARGE', shortName: 'XL' },
          { value: '29', label: '29', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        bottom: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '13.5', label: '13.5', name: 'XSMALL', shortName: 'XS' },
          { value: '15.5', label: '15.5', name: 'SMALL', shortName: 'S' },
          { value: '17', label: '17', name: 'MEDIUM', shortName: 'M' },
          { value: '19.5', label: '19.5', name: 'LARGE', shortName: 'L' },
          { value: '21.5', label: '21.5', name: 'XLARGE', shortName: 'XL' },
          { value: '22.5', label: '22.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        shoulder: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '14', label: '14', name: 'XSMALL', shortName: 'XS' },
          { value: '15.5', label: '15.5', name: 'SMALL', shortName: 'S' },
          { value: '17.5', label: '17.5', name: 'MEDIUM', shortName: 'M' },
          { value: '19', label: '19', name: 'LARGE', shortName: 'L' },
          { value: '21', label: '21', name: 'XLARGE', shortName: 'XL' },
          { value: '22', label: '22', name: 'XXLARGE', shortName: '2XL' },
          { value: '-', label: '-', name: '3XSMALL', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
      },
    },
    female: {
      centimeters: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '39', label: '39', name: 'SMALL', shortName: 'S' },
          { value: '44', label: '44', name: 'MEDIUM', shortName: 'M' },
          { value: '49.5', label: '49.5', name: 'LARGE', shortName: 'L' },
          { value: '55', label: '55', name: 'XLARGE', shortName: 'XL' },
          { value: '61', label: '61', name: 'XXLARGE', shortName: '2XL' },
          { value: '63.5', label: '63.5', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '61', label: '61', name: 'SMALL', shortName: 'S' },
          { value: '65', label: '65', name: 'MEDIUM', shortName: 'M' },
          { value: '70', label: '70', name: 'LARGE', shortName: 'L' },
          { value: '71', label: '71', name: 'XLARGE', shortName: 'XL' },
          { value: '72', label: '72', name: 'XXLARGE', shortName: '2XL' },
          { value: '79', label: '79', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        back: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '60', label: '60', name: 'SMALL', shortName: 'S' },
          { value: '63.5', label: '63.5', name: 'MEDIUM', shortName: 'M' },
          { value: '67', label: '67', name: 'LARGE', shortName: 'L' },
          { value: '70', label: '70', name: 'XLARGE', shortName: 'XL' },
          { value: '71', label: '71', name: 'XXLARGE', shortName: '2XL' },
          { value: '74', label: '74', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        bottom: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '34', label: '34', name: 'SMALL', shortName: 'S' },
          { value: '39', label: '39', name: 'MEDIUM', shortName: 'M' },
          { value: '43', label: '43', name: 'LARGE', shortName: 'L' },
          { value: '49.5', label: '49.5', name: 'XLARGE', shortName: 'XL' },
          { value: '55', label: '55', name: 'XXLARGE', shortName: '2XL' },
          { value: '57', label: '57', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        shoulder: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '36', label: '36', name: 'XSMALL', shortName: 'S' },
          { value: '39', label: '39', name: 'SMALL', shortName: 'M' },
          { value: '44', label: '44', name: 'MEDIUM', shortName: 'L' },
          { value: '48', label: '48', name: 'LARGE', shortName: 'XL' },
          { value: '53', label: '53', name: 'XLARGE', shortName: '2XL' },
          { value: '56', label: '56', name: 'XXLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '15.5', label: '15.5', name: 'SMALL', shortName: 'S' },
          { value: '17.5', label: '17.5', name: 'MEDIUM', shortName: 'M' },
          { value: '19.5', label: '19.5', name: 'LARGE', shortName: 'L' },
          { value: '21.5', label: '21.5', name: 'XLARGE', shortName: 'XL' },
          { value: '24', label: '24', name: 'XXLARGE', shortName: '2XL' },
          { value: '25', label: '25', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        sleeve: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '24', label: '24', name: 'SMALL', shortName: 'S' },
          { value: '25.5', label: '25.5', name: 'MEDIUM', shortName: 'M' },
          { value: '27.5', label: '27.5', name: 'LARGE', shortName: 'L' },
          { value: '28', label: '28', name: 'XLARGE', shortName: 'XL' },
          { value: '28.5', label: '28.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '31', label: '31', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        back: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '23.5', label: '23.5', name: 'SMALL', shortName: 'S' },
          { value: '25', label: '25', name: 'MEDIUM', shortName: 'M' },
          { value: '26.5', label: '26.5', name: 'LARGE', shortName: 'L' },
          { value: '27.5', label: '27.5', name: 'XLARGE', shortName: 'XL' },
          { value: '28', label: '28', name: 'XXLARGE', shortName: '2XL' },
          { value: '29', label: '29', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        bottom: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '13.5', label: '13.5', name: 'SMALL', shortName: 'S' },
          { value: '15.5', label: '15.5', name: 'MEDIUM', shortName: 'M' },
          { value: '17', label: '17', name: 'LARGE', shortName: 'L' },
          { value: '19.5', label: '19.5', name: 'XLARGE', shortName: 'XL' },
          { value: '21.5', label: '21.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '22.5', label: '22.5', name: '3XLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
        shoulder: [
          { value: '-', label: '-', name: 'XXSMALL', shortName: 'XXS' },
          { value: '-', label: '-', name: 'XSMALL', shortName: 'XS' },
          { value: '14', label: '14', name: 'XSMALL', shortName: 'S' },
          { value: '15.5', label: '15.5', name: 'SMALL', shortName: 'M' },
          { value: '17.5', label: '17.5', name: 'MEDIUM', shortName: 'L' },
          { value: '19', label: '19', name: 'LARGE', shortName: 'XL' },
          { value: '21', label: '21', name: 'XLARGE', shortName: '2XL' },
          { value: '22', label: '22', name: 'XXLARGE', shortName: '3XL' },
          { value: '-', label: '-', name: '4XSMALL', shortName: '4XL' },
          { value: '-', label: '-', name: '5XSMALL', shortName: '5XL' },
          { value: '-', label: '-', name: '6XSMALL', shortName: '6XL' },
        ],
      },
    },
  },
  Croptop: {
    female: {
      centimeters: {
        chest: [
          { value: '66-71', label: '66-71', name: 'XXSMALL', shortName: 'XXS' },
          { value: '76-81', label: '76-81', name: 'XSMALL', shortName: 'XS' },
          { value: '86-91', label: '86-91', name: 'SMALL', shortName: 'S' },
          { value: '96.5-102', label: '96.5-102', name: 'MEDIUM', shortName: 'M' },
          { value: '102-107', label: '102-107', name: 'LARGE', shortName: 'L' },
          { value: '112-117', label: '112-117', name: 'XLARGE', shortName: 'XL' },
          { value: '122-127', label: '122-127', name: 'XXLARGE', shortName: '2XL' },
          { value: '132-137', label: '132-137', name: '3XLARGE', shortName: '3XL' },
          { value: '142-147', label: '142-147', name: '4XLARGE', shortName: '4XL' },
          { value: '152-157', label: '152-157', name: '5XLARGE', shortName: '5XL' },
          { value: '162.5-168', label: '162.5-168', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '66', label: '66', name: 'XXSMALL', shortName: 'XXS' },
          { value: '66.5', label: '66.5', name: 'XSMALL', shortName: 'XS' },
          { value: '67', label: '67', name: 'SMALL', shortName: 'S' },
          { value: '68', label: '68', name: 'MEDIUM', shortName: 'M' },
          { value: '68.5', label: '68.5', name: 'LARGE', shortName: 'L' },
          { value: '69', label: '69', name: 'XLARGE', shortName: 'XL' },
          { value: '70', label: '70', name: 'XXLARGE', shortName: '2XL' },
          { value: '70.5', label: '70.5', name: '3XLARGE', shortName: '3XL' },
          { value: '71', label: '71', name: '4XLARGE', shortName: '4XL' },
          { value: '77', label: '77', name: '5XLARGE', shortName: '5XL' },
          { value: '78', label: '78', name: '6XLARGE', shortName: '6XL' },
        ],
        back: [
          { value: '44', label: '44', name: 'XXSMALL', shortName: 'XXS' },
          { value: '46', label: '46', name: 'XSMALL', shortName: 'XS' },
          { value: '47', label: '47', name: 'SMALL', shortName: 'S' },
          { value: '48', label: '48', name: 'MEDIUM', shortName: 'M' },
          { value: '49.5', label: '49.5', name: 'LARGE', shortName: 'L' },
          { value: '51', label: '51', name: 'XLARGE', shortName: 'XL' },
          { value: '52', label: '52', name: 'XXLARGE', shortName: '2XL' },
          { value: '53', label: '53', name: '3XLARGE', shortName: '3XL' },
          { value: '55', label: '55', name: '4XLARGE', shortName: '4XL' },
          { value: '61', label: '61', name: '5XLARGE', shortName: '5XL' },
          { value: '62', label: '62', name: '6XLARGE', shortName: '6XL' },
        ],
        waist: [
          { value: '71', label: '71', name: 'XXSMALL', shortName: 'XXS' },
          { value: '76', label: '76', name: 'XSMALL', shortName: 'XS' },
          { value: '81', label: '81', name: 'SMALL', shortName: 'S' },
          { value: '86', label: '86', name: 'MEDIUM', shortName: 'M' },
          { value: '91', label: '91', name: 'LARGE', shortName: 'L' },
          { value: '96.5', label: '96.5', name: 'XLARGE', shortName: 'XL' },
          { value: '102', label: '102', name: 'XXLARGE', shortName: '2XL' },
          { value: '107', label: '107', name: '3XLARGE', shortName: '3XL' },
          { value: '112', label: '112', name: '4XLARGE', shortName: '4XL' },
          { value: '117', label: '117', name: '5XLARGE', shortName: '5XL' },
          { value: '122', label: '122', name: '6XLARGE', shortName: '6XL' },
        ],
      },
      inches: {
        chest: [
          { value: '26-28', label: '26-28', name: 'XXSMALL', shortName: 'XXS' },
          { value: '30-32', label: '30-32', name: 'XSMALL', shortName: 'XS' },
          { value: '34-36', label: '34-36', name: 'SMALL', shortName: 'S' },
          { value: '38-40', label: '38-40', name: 'MEDIUM', shortName: 'M' },
          { value: '40-42', label: '40-42', name: 'LARGE', shortName: 'L' },
          { value: '44-46', label: '44-46', name: 'XLARGE', shortName: 'XL' },
          { value: '48-50', label: '48-50', name: 'XXLARGE', shortName: '2XL' },
          { value: '52-54', label: '52-54', name: '3XLARGE', shortName: '3XL' },
          { value: '56-58', label: '56-58', name: '4XLARGE', shortName: '4XL' },
          { value: '60-62', label: '60-62', name: '5XLARGE', shortName: '5XL' },
          { value: '64-66', label: '64-66', name: '6XLARGE', shortName: '6XL' },
        ],
        sleeve: [
          { value: '26', label: '26', name: 'XXSMALL', shortName: 'XXS' },
          { value: '26.25', label: '26.25', name: 'XSMALL', shortName: 'XS' },
          { value: '26.5', label: '26.5', name: 'SMALL', shortName: 'S' },
          { value: '26.75', label: '26.75', name: 'MEDIUM', shortName: 'M' },
          { value: '27', label: '27', name: 'LARGE', shortName: 'L' },
          { value: '27.25', label: '27.25', name: 'XLARGE', shortName: 'XL' },
          { value: '27.5', label: '27.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '27.75', label: '27.75', name: '3XLARGE', shortName: '3XL' },
          { value: '28', label: '28', name: '4XLARGE', shortName: '4XL' },
          { value: '30.25', label: '30.25', name: '5XLARGE', shortName: '5XL' },
          { value: '30.5', label: '30.5', name: '6XLARGE', shortName: '6XL' },
        ],
        back: [
          { value: '17.5', label: '17.5', name: 'XXSMALL', shortName: 'XXS' },
          { value: '18', label: '18', name: 'XSMALL', shortName: 'XS' },
          { value: '18.5', label: '18.5', name: 'SMALL', shortName: 'S' },
          { value: '19', label: '19', name: 'MEDIUM', shortName: 'M' },
          { value: '19.5', label: '19.5', name: 'LARGE', shortName: 'L' },
          { value: '20', label: '20', name: 'XLARGE', shortName: 'XL' },
          { value: '20.5', label: '20.5', name: 'XXLARGE', shortName: '2XL' },
          { value: '21', label: '21', name: '3XLARGE', shortName: '3XL' },
          { value: '21.5', label: '21.5', name: '4XLARGE', shortName: '4XL' },
          { value: '24', label: '24', name: '5XLARGE', shortName: '5XL' },
          { value: '24.5', label: '24.5', name: '6XLARGE', shortName: '6XL' },
        ],
        waist: [
          { value: '28', label: '28', name: 'XXSMALL', shortName: 'XXS' },
          { value: '30', label: '30', name: 'XSMALL', shortName: 'XS' },
          { value: '32', label: '32', name: 'SMALL', shortName: 'S' },
          { value: '34', label: '34', name: 'MEDIUM', shortName: 'M' },
          { value: '36', label: '36', name: 'LARGE', shortName: 'L' },
          { value: '38', label: '38', name: 'XLARGE', shortName: 'XL' },
          { value: '40', label: '40', name: 'XXLARGE', shortName: '2XL' },
          { value: '42', label: '42', name: '3XLARGE', shortName: '3XL' },
          { value: '44', label: '44', name: '4XLARGE', shortName: '4XL' },
          { value: '46', label: '46', name: '5XLARGE', shortName: '5XL' },
          { value: '48', label: '48', name: '6XLARGE', shortName: '6XL' },
        ],
      },
    },
  },
}

export const sizeDataRows = {
  Jacket: [
    { field: 'chest', title: 'Chest' },
    { field: 'hips', title: 'Hips' },
    { field: 'sleeve', title: 'Sleeve' },
  ],
  Coat: [
    { field: 'chest', title: 'Chest' },
    { field: 'sleeve', title: 'Sleeve' },
    { field: 'shoulderFull', title: 'Shoulders' },
    { field: 'backLength', title: 'Back' },
  ],
  Hoodie: [
    { field: 'sleeve', title: 'Sleeve' },
    { field: 'chest', title: 'Chest' },
    { field: 'back', title: 'High' },
  ],
  Croptop: [
    { field: 'chest', title: 'Chest' },
    { field: 'sleeve', title: 'Sleeve' },
    { field: 'back', title: 'Back' },
    { field: 'waist', title: 'Waist' },
  ],
}

export const sizeDataImages = {
  Jacket: {
    male: getImgPath('/img/uploads/jackets-male.jpg'),
    female: getImgPath('/img/uploads/jackets-female.jpg'),
  },
  Satinjack: {
    male: getImgPath('/img/uploads/satin-size-jackets-male.jpg'),
    female: getImgPath('/img/uploads/jackets-female.jpg'),
  },
  Hoodie: {
    male: getImgPath('/img/uploads/hoodies-male.jpg'),
    female: getImgPath('/img/uploads/hoodies-female.jpg'),
  },
  Coat: {
    male: getImgPath('/img/uploads/coats-male.jpg'),
    female: getImgPath('/img/uploads/coats-female.jpg'),
  },
  Croptop: {
    female: getImgPath('/img/uploads/size-chart-croptop.jpg'),
  },
}
