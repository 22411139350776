<template>
  <div id="sizeSelectionModal" :class="['uk-flex-top', { 'white-mode': !isDark }]" data-uk-modal>
    <template v-if="loading">
      <!--LOADING OVERLAY-->
      <div class="uk-overlay-primary uk-position-cover" />
      <div class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
      <!--end of LOADING OVERLAY-->
    </template>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" aria-label="close" data-uk-close />

      <div class="uk-modal-header uk-padding uk-padding-remove-bottom uk-margin-bottom" style="border: none">
        <h3>Choose Your Size</h3>
      </div>

      <div class="uk-modal-body uk-padding uk-padding-remove-top uk-padding-remove-bottom" data-uk-overflow-auto>
        <!--Size Selection-->
        <div :class="['uk-margin-bottom', { 'uk-light': isDark }]">
          <div class="uk-margin-bottom">
            <div class="uk-child-width-1-3 uk-child-width-1-4@m uk-grid-small" data-uk-grid>
              <div v-for="size in productSizes" :key="size.name">
                <label class="w-radio-label">
                  <input v-model="currentSize" class="uk-radio" type="radio" :value="size" required />
                  <span class="w-label uk-flex uk-flex-middle uk-flex-center">{{ size.short_name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!--/Size Selection-->

        <p class="uk-text-small">
          <strong>Example:</strong>
          If you are 6’(183cm) tall, weigh 175lbs(80kg), chest 40’(101cm), waist 33’(84cm) we suggest size LARGE
        </p>

        <!--Size Calculator-->
        <div id="sizes">
          <div class="uk-margin-bottom uk-flex">
            <label class="w-radio uk-margin-small-right uk-flex uk-flex-middle">
              <input
                id="inchesRecMen"
                class="uk-radio"
                type="radio"
                name="recommended_size_men"
                :checked="selectedLocal === 'inches'"
                @change="handleChoseLocal('inches')"
              />
              <span class="w-label">In inches</span>
            </label>

            <label class="w-radio uk-flex uk-flex-middle">
              <input
                id="cmRecMen"
                class="uk-radio"
                type="radio"
                name="recommended_size_men"
                :checked="selectedLocal === 'centimeters'"
                @change="handleChoseLocal('centimeters')"
              />
              <span class="w-label">In centimeters</span>
            </label>
          </div>

          <div class="uk-child-width-expand uk-grid-small uk-margin-bottom" data-uk-grid>
            <div>
              <select
                id="sizeChest"
                v-model="selectedChest"
                :class="['uk-select uk-form-small', { 'w-select': !isDark }]"
                name="sizeChest"
                aria-label="Chest size"
                required
              >
                <option disabled selected>Chest</option>
                <option v-for="(chest, index) in chestOptions" :key="chest.value" :value="index">
                  {{ chest.label }}
                </option>
              </select>
            </div>
            <div>
              <select
                id="sizeSleeve"
                v-model="selectedWaist"
                :class="['uk-select uk-form-small', { 'w-select': !isDark }]"
                name="sizeSleeve"
                aria-label="Sleeve size"
                required
              >
                <option disabled selected>Sleeve</option>
                <option v-for="(waist, index) in waistOptions" :key="waist.value" :value="index">
                  {{ waist.label }}
                </option>
              </select>
            </div>
          </div>

          <p class="uk-text-small">
            Your recommended size is:
            <strong class="uk-h4 uk-text-bold uk-margin-small-left">{{ sizeOut }}</strong>
          </p>
        </div>
        <!-- /Size Calculator-->

        <div class="uk-overflow-auto uk-margin-bottom">
          <table id="sizeChartTable" class="uk-table uk-table-divider uk-text-small uk-table-small">
            <thead>
              <tr>
                <th>Body Measurement</th>
                <th>Inch</th>
                <th>Cm</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Chest</th>
                <td>
                  <span class="uk-text-muted measureToAddInch">{{ chosenChestInch }}</span>
                </td>
                <td>
                  <span class="uk-text-muted measureToAddCm">{{ chosenChestCentimeters }}</span>
                </td>
              </tr>
              <tr>
                <th>Sleeve</th>
                <td>
                  <span class="uk-text-muted measureToAddInch">{{ chosenSleeveInch }}</span>
                </td>
                <td>
                  <span class="uk-text-muted measureToAddCm">{{ chosenSleeveCentimeters }}</span>
                </td>
              </tr>
              <tr v-if="archetype === 'Coat'">
                <th>Back</th>
                <td>
                  <span class="uk-text-muted measureToAddInch">{{ chosenBackInch }}</span>
                </td>
                <td>
                  <span class="uk-text-muted measureToAddCm">{{ chosenBackCentimeters }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <img :data-src="imgPaths[archetype]" width="950" alt="Size Chart" data-uk-img />

        <p class="uk-text-small uk-margin-remove-bottom">
          These are the measurements of your body (not the {{ archetype.toLowerCase() }}). To measure your arm length
          correctly, stand up straight with your arms down at your side and measure from where the shoulder meets the
          arm all the way down to your knuckles (not wrist).
        </p>
        <p class="uk-text-small uk-margin-small-top uk-margin-bottom">
          The sizes we recommended are only suggestions. We are not responsible if you order an incorrect size. If you
          are unsure of your size please write to us first.
        </p>
      </div>

      <div class="uk-modal-footer" style="border: none">
        <button
          :disabled="!selectedSize || loading"
          :data-uk-toggle="isSidebar ? 'target: #offCanvasCart' : undefined"
          type="button"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom"
          @click.prevent="$emit('submit')"
        >
          <span data-uk-icon="cart" />
          <span>Add To Cart</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { sizeDataImages, sizesData } from '../constants/sizesData'

export default {
  name: 'SizeSelectionModal',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
    productSizes: {
      type: Array,
      default: () => [],
    },
    selectedSize: {
      type: Object,
      default: () => {},
    },
    archetype: {
      type: String,
      default: '',
    },
  },
  emits: ['submit', 'size'],
  data() {
    return {
      chosenBackInch: '—',
      chosenBackCentimeters: '—',
      chosenChestInch: '—',
      chosenSleeveInch: '—',
      chosenChestCentimeters: '—',
      chosenSleeveCentimeters: '—',
      selectedLocal: 'inches',
      selectedChest: null,
      selectedWaist: null,
      imgPaths: {
        Jacket: sizeDataImages.Jacket.male,
        Satinjack: sizeDataImages.Satinjack.male,
        Hoodie: sizeDataImages.Hoodie.male,
        Coat: sizeDataImages.Coat.male,
        Croptop: sizeDataImages.Croptop.female,
      },
    }
  },
  computed: {
    currentSize: {
      get() {
        return this.selectedSize
      },
      set(size) {
        this.$emit('size', size)
      },
    },
    currentSizes() {
      return sizesData[this.archetype] || {}
    },
    chestOptions() {
      return this.getOptions(this.currentSizes[this.selectedLocal]?.chest)
    },
    waistOptions() {
      return this.getOptions(this.currentSizes[this.selectedLocal]?.sleeve)
    },
    sizeOut() {
      return this.selectedChest !== null || this.selectedWaist !== null
        ? this.chestOptions[Math.max(this.selectedChest, this.selectedWaist)]?.shortName
        : '...'
    },
  },
  watch: {
    selectedSize: {
      immediate: true,
      handler(value) {
        const { inches, centimeters } = this.currentSizes

        if (value?.name && inches && centimeters) {
          this.chosenBackInch = inches.back?.find(item => item.name === value.name)?.value || '—'
          this.chosenBackCentimeters = centimeters.back?.find(item => item.name === value.name)?.value || '—'

          this.chosenChestInch = inches.chest?.find(item => item.name === value.name)?.value || '—'
          this.chosenChestCentimeters = centimeters.chest?.find(item => item.name === value.name)?.value || '—'

          this.chosenSleeveInch = inches.sleeve?.find(item => item.name === value.name)?.value || '—'
          this.chosenSleeveCentimeters = centimeters.sleeve?.find(item => item.name === value.name)?.value || '—'
        }
      },
    },
  },
  methods: {
    handleChoseLocal(type) {
      this.selectedLocal = type
      this.selectedChest = null
      this.selectedWaist = null
    },
    getOptions(options = []) {
      return options.filter(option => Boolean(this.productSizes.find(s => s.name === option.name)))
    },
  },
}
</script>
